export default {
	computed: {
		playerInviteAndEarnDomain() {
			return this.$store.state.player.playerInviteAndEarnDomain
		},
		formRule() {
			return {
				email: [
					(v) =>
						!v ||
						/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
						this.tl('emailFormatInvalid'),
				],
				date_of_birth: [
					(v) =>
						!!v ||
						`${this.tl('birthDate')} ${this.tl('isRequired')}`,
				],
				phone: [
					(v) =>
						!!v ||
						`${this.tl('phoneNumber')} ${this.tl('isRequired')}`,
				],
				social_media_id: [
					(v) =>
						!!v ||
						`${this.tl('socialMediaId')} ${this.tl('isRequired')}`,
				],
				social_media_type: [
					(v) =>
						!!v ||
						`${this.tl('platform')} ${this.tl('isRequired')}`,
				],
			}
		},
		platforms() {
			return [
				{
					val: null,
					text: this.tl('none'),
				},
				{
					val: 1,
					text: 'Telegram',
				},
				{
					val: 2,
					text: 'Zalo ID',
				},
				{
					val: 3,
					text: 'Line',
				},
				{
					val: 4,
					text: 'Facebook',
				},
				{
					val: 5,
					text: 'Whatsapp',
				},
				{
					val: 6,
					text: 'WecChat',
				},
			]
		},
		playerReferralLink() {
			const playerId = this.$store.state?.player?.player?.player_id || this.$cookie?.get('player')?.player_id
			const referralDomain = this.playerInviteAndEarnDomain.domain || this.$cookie.get('referral_domain');
			return referralDomain ? `https://${referralDomain}/u/${playerId}` : '';
		}
	},
	methods: {
		isEqual(obj1, obj2) {
			if (obj1 === obj2) {
				return true
			}

			if (obj1 == null || obj2 == null) {
				return false
			}

			if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
				return false
			}

			const keys1 = Object.keys(obj1)
			const keys2 = Object.keys(obj2)

			if (keys1.length !== keys2.length) {
				return false
			}

			for (const key of keys1) {
				if (!keys2.includes(key)) {
					return false
				}

				if (
					typeof obj1[key] === 'object' &&
					typeof obj2[key] === 'object'
				) {
					if (!this.isEqual(obj1[key], obj2[key])) {
						return false
					}
				} else if (obj1[key] !== obj2[key]) {
					return false
				}
			}
			return true
		},
		updateProfile() {
			this.$refs?.refProfile.validate()
			if (this.formValid) {
				this.$store.dispatch('themes/player/doUpdatePlayerProfile', {
					...this.formData,
				})
			}
		},
	}
}