import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      showDisableAccount: false,
    }
  },
  computed: {
    ...mapGetters({
      bankAccount: 'themes/player-bank/bankAccount',
    }),
  },
  watch: {
    bankAccount(value) {
      this.setDefaultToCard(value)
    },
  },
  mounted() {
    if (this.bankAccount?.player_bank_accounts?.length > 0) {
      this.setDefaultToCard(this.bankAccount)
    }
  },
  methods: {
    setDefaultToCard(bankAccount) {
      const accounts = bankAccount?.player_bank_accounts

      const primaryAccount = accounts?.find(
        (account) => account.is_primary === true
      )

      if (this.showDisableAccount && !primaryAccount?.bank_id) {
        if (accounts.length > 1) {
          this.onSelectedBankAccount(accounts[1]?.id)
        } else {
          this.onSelectedBankAccount('')
        }
      } else {
        this.onSelectedBankAccount(primaryAccount?.id)
      }
    },
    onSelectedBankAccount(id) {
      if (this.selectedBankId === id || !id) {
        this.selectedBankId = null
        this.pleaseSelectedBankId = true
      } else {
        this.selectedBankId = id
        this.pleaseSelectedBankId = false
      }
    },
  },
}
